@include each-breakpoint using ($prefix) {
	.#{$prefix}min-w-0 {
		min-width: 0;
	}

	.#{$prefix}min-w-full {
		min-width: 100%;
	}

	.#{$prefix}min-w-min {
		min-width: min-content;
	}

	.#{$prefix}min-w-max {
		min-width: max-content;
	}

	.#{$prefix}min-w-fit {
		min-width: fit-content;
	}
}
