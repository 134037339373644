.accordion {
	.accordion-heading-wrapper {
		cursor: pointer;

		.accordion-arrow {
			display: flex;
			justify-content: space-between;
			padding-top: 1rem;
			padding-bottom: 3rem;
			height: 0;

			.line-left {
				border-bottom: 1px solid $primary;
				width: calc(85% - 2.83rem);
			}

			.line-right {
				border-bottom: 1px solid $primary;
				width: calc(15% - 2.83rem);
			}

			.arrow {
				right: 10%;
				width: 4rem;
				height: 4rem;
				transform: translateY(-49.5%) rotate(45deg);
				border-top: 0;
				border-right: 1px solid $primary;
				border-bottom: 1px solid $primary;
				border-left: 0;
				pointer-events: none;
			}
		}
		&:not(.collapsed) {
			.accordion-arrow {
				padding-top: 3rem;
				padding-bottom: 1rem;
				.arrow {
					border-top: 1px solid $primary;
					border-right: 0;
					border-bottom: 0;
					border-left: 1px solid $primary;
				}
			}
		}
		&.collapsed, &:not([aria-expanded]) {
			.accordion-arrow {
				padding-top: 1rem;
				padding-bottom: 3rem;
				.arrow {
					border-top: 0;
					border-right: 1px solid $primary;
					border-bottom: 1px solid $primary;
					border-left: 0;
				}
			}
		}
	}
	.wrap:first-of-type { // For first accordion element, which is auto-expanded. Properly sets initial styles on arrow.
		.accordion-heading-wrapper {
			&:not(.collapsed):not([aria-expanded]) {
				.accordion-arrow {
					padding-top: 3rem;
					padding-bottom: 1rem;
					.arrow {
						border-top: 1px solid $primary;
						border-right: 0;
						border-bottom: 0;
						border-left: 1px solid $primary;
					}
				}
			}
		}
	}
}