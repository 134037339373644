.toggle {
	position: relative;
	width: 60px;
	height: 34px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .slider {
			background-color: $success;
			&:before {
				transform: translateX(26px);
			}
		}
		&:focus + .slider {
			box-shadow: 0 0 1px $success;
		}
	}
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		transition: $transition-base;
		border-radius: 34px;
		&:before {
			position: absolute;
			content: '';
			width: 26px;
			height: 26px;
			left: 4px;
			bottom: 4px;
			background-color: #fff;
			transition: $transition-base;
			border-radius: 34px;
		}
	}
}