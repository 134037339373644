form{
    .form-label-group{
        display: flex; 
        flex-direction: column-reverse;
        > label, > select ~ label.dropdown-label{
            position: static !important;
            padding: 0 !important;
            font-size: 1rem !important;
            visibility: visible !important;
        }
        select{
            @media screen and (min-width:0\0){
                background-image: none;
            }
            &.selected{
                padding: 0.75rem
            }
        }
    }
}