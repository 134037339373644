@include each-breakpoint using ($prefix) {
	@for $i from 0 through 5 {
		$class: $i * 10;
		@each $n in ('' '-') {
			@if not ($n == '-' and $i == 0) {
				.#{$prefix}#{$n}z-#{$class} {
					@if $n == '-' {
						$class: $class * -1;
					}
					z-index: $class;
				}
			}
		}
	}

	.#{$prefix}z-auto {
		z-index: auto;
	}
}
