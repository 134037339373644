@mixin font-variant-numeric {
	font-variant-numeric: var(--mb-ordinal) var(--mb-slashed-zero) var(--mb-numeric-figure) var(--mb-numeric-spacing) var(--mb-numeric-fraction);
}

@include each-breakpoint using ($prefix) {
	@each $class, $size in $font-sizes {
		.#{$prefix}text-#{$class} {
			@include font-size($size);
		}
	}

	@each $name, $weight in $font-weights {
		.#{$prefix}font-weight-#{$name} {
			font-weight: $weight;
		}
	}

	.#{$prefix}ordinal, .#{$prefix}slashed-zero, .#{$prefix}linine-nums, .#{$prefix}oldstyle-nums,
	.#{$prefix}proportional-nums, .#{$prefix}tabular-nums, .#{$prefix}diagonal-fractions, .#{$prefix}stacked-fractions {
		--mb-ordinal: ' ';
		--mb-slashed-zero: ' ';
		--mb-numeric-figure: ' ';
		--mb-numeric-spacing: ' ';
		--mb-numeric-fraction: ' ';
	}

	.#{$prefix}ordinal {
		--mb-ordinal: 'ordinal';
	}

	.#{$prefix}slashed-zero {
		--mb-slashed-zero: 'slashed-zero';
	}

	.#{$prefix}lining-nums {
		--mb-numeric-figure: 'lining-nums';
	}

	.#{$prefix}oldstyle-nums {
		--mb-numeric-figure: 'oldstyle-nums';
	}

	.#{$prefix}proportional-nums {
		--mb-numeric-spacing: 'proportional-nums';
	}

	.#{$prefix}tabular-nums {
		--mb-numeric-spacing: 'tabular-nums';
	}

	.#{$prefix}diagonal-fractions {
		--mb-numeric-fraction: 'diagonal-fractions';
	}

	.#{$prefix}stacked-fractions {
		--mb-numeric-fraction: 'stacked-fractions';
	}

	.#{$prefix}ordinal, .#{$prefix}slashed-zero, .#{$prefix}lining-nums, .#{$prefix}oldstyle-nums, .#{$prefix}proportional-nums, .#{$prefix}tabular-nums, .#{$prefix}diagonal-fractions, .#{$prefix}stacked-fractions {
		@include font-variant-numeric;
	}
}
