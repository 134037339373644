html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    // top: 167px; /* Height of navbar */
    top: 0;
    bottom: 0;
    left: 100%;
    width: 60%;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 3rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: rgba($primary, 0.9);
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-property: left, visibility;
    z-index: 999;
  }
  .offcanvas-collapse.open {
    left: 40%;
    visibility: visible;
    z-index: 999;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba($white, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 { color: rgba($white, .5); }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }

.navbar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 998;
  background-color: rgba($white, 0.3);
  transition-property: left, right;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}
.open .navbar-overlay {
  visibility: visible;
  right: 60%;
  left: 0;
}
