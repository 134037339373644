$heading-font-name: "Lato";
$heading-font-file: "Lato";
$fetch-heading-font: false;

$paragraph-font-name: "Lato";
$paragraph-font-file: "lato";
$fetch-paragraph-font: false;

$special-font-name: null;
$special-font-file: null;
$fetch-special-font: false;

$primary: #052987;
$secondary: #0881C2;
$light: #F7F7F7;
$dark: #F0F0F0;
$body-bg: #fff;
$body-color: #4E5255;
$alt-text-grey: #868686;

$enable-responsive-font-sizes: true;


$grid-gutter-width: 2rem;

$embed-responsive-aspect-ratios: (
	(3 2),
	(9 16),
	(1 2),
);

$headings-color: $primary;

// Tailwind spacing formula: Class = 4 * margin
$spacers: (
	16: 4rem,
	20:	5rem,
	24:	6rem,
	25: 6.25rem,
	28: 7rem,
	30: 7.5rem,
	32: 8rem,
	36: 9rem,
	40: 10rem,
	44: 11rem,
	48: 12rem,
	52: 13rem,
	56: 14rem,
	60: 15rem,
	64: 16rem,
	68: 17rem,
	72: 18rem,
	76: 19rem,
	80: 20rem,
	84: 21rem,
	88: 22rem,
	92: 23rem,
	96: 24rem,
);

// Intrepid Variables
$text-base: 1rem;
$font-sizes: (
	base: $text-base,
	xs:   $text-base * 0.75,
	sm:   $text-base * 0.875,
	lg:   $text-base * 1.125,
	xl:   $text-base * 1.25,
	2xl:  $text-base * 1.5,
	3xl:  $text-base * 1.875,
	4xl:  $text-base * 2.25,
	5xl:  $text-base * 3,
	6xl:  $text-base * 3.75,
	7xl:  $text-base * 4.5,
	8xl:  $text-base * 6,
	9xl:  $text-base * 8,
);

$font-weights: (
	'thin': 100,
	'hairline': 100,
	'extralight': 200,
	'ultralight': 200,
	'light': 300,
	'book': 350,
	'normal': 400,
	'regular': 400,
	'medium': 500,
	'semibold': 600,
	'demibold': 600,
	'bold': 700,
	'extrabold': 800,
	'ultrabold': 800,
	'black': 900,
	'heavy': 900,
	'extrablack': 950,
);
