.vue-archive {
    div {
        transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
    }

    .loading {
        opacity: 0.1;
    }

    .vue-pagination {
        .page-link {
            font-size: 1.75rem;
            background: none;
            outline: none;
            border: none;
            &:focus {
                box-shadow: none;
            }
        }
    }

    .social-share-btn {
        padding: 0 0.5rem;
    }
}
.archive-item {
    border-bottom: 0.25rem solid $primary;
    &:first-of-type {
        border-top: 0.25rem solid $primary;
    }
    .testimonial-quote {
        :last-child {
            margin-bottom: 0;
        }
    }
}