@use 'sass:string';

$heights: (
	'full': 100%,
	'screen': 100vh,
	'min': min-content,
	'max': max-content,
	'fit': fit-content,
);
@include each-breakpoint using ($prefix) {
	@include each-size using ($class, $value) {
		.#{$prefix}h-#{$class} {
			height: $value;
		}
	}

	.#{$prefix}h-auto {
		height: auto;
	}

	$denominator: 1;
	@while $denominator < 13 {
		$numerator: 1;
		@while $numerator < $denominator {
			.#{$prefix}h-#{$numerator}\/#{$denominator} {
				height: ($numerator / $denominator) * 100%;
			}
			$numerator: $numerator + 1;
		}

		$denominator: $denominator + 1;
		@if $denominator == 7 {
			$denominator: 12;
		}
	}

	@each $class, $value in $heights {
		.#{$prefix}h-#{$class} {
			height: $value;
		}
	}
}
