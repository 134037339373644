@import 'mixins/buttons';

@mixin centerer() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin vcenterer() {
	position: absolute;
	top: 50%;
	transform: transateY(-50%);
}
@mixin hcenterer() {
	position: absolute;
	right: 50%;
	transform: translateY(50%);
}
@mixin placeholder {
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content}  
}

@mixin this-placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}

@function mb-breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints) == null, "", "#{$name}\\:");
}

@function prefix($name, $prefix: '-') {
	@return if($name == '', '', '-#{$name}')
}

@mixin each-breakpoint {
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-breakpoint-up($breakpoint) {
			$prefix: mb-breakpoint-infix($breakpoint, $grid-breakpoints);
			@content($prefix)
		}
	}
}

@mixin each-size {
	@for $i from 0 through 8 {
		$class: $i / 2;
		$value: ($class / 4) * 1rem;
		@if str-index('#{$class}', '.') == 2 {
			$class: str-replace('#{$class}', '.', '\\.');
		}

		@content($class, $value);
	}

	@for $i from 5 through 16 {
		$class: $i;
		$value: ($i / 4) * 1rem;
		@content($class, $value);
	}

	$i: 20;
	@while $i < 100 {
		$class: $i;
		$value: ($i / 4) * 1rem;
		@content($class, $value);

		$i: $i + 4;
	}
}

@mixin range($start, $limit) {
	@if $start > $limit {
		@warn "Lower bounds (#{$start}) is greater than upper limit (#{$limit})"
	}
	@for $i from $start through $limit {
		@content($i);
	}
}

@mixin media-min($width) {
	@media (min-width: $width) {
		@content
	}
}

@mixin media-max($width) {
	@media (max-width: $width) {
		@content
	}
}
