.hero-video {
	.hero-video__container {
		position: relative;
		height: 500px;
		overflow: hidden;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.333333);
			pointer-events: none;
		}
	}
	.hero-video__video {
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		transform: translate(-50%, -50%);
	}
	.hero-video__play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&:hover {
			cursor: pointer;
		}
	}
	.hero-video__image-cover {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.hero-video-modal {
	.modal-dialog {
		max-width: 800px;
		margin: 30px auto 0;
	}

	.modal-body {
		position: relative;
		padding: 0px;
	}
	.close {
		position: absolute;
		right: -50px;
		top: -20px;
		z-index: 999;
		font-size: 2rem;
		font-weight: normal;
		color: #fff;
		opacity: 1;
		&::before, &::after {
			background-color: $white;
		}
	}
}
