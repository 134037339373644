.component-image-text-and-link.component-image-text-and-link__layout-default {
	a {
		color: $body-color;
		border-bottom: 1px solid $body-color;
		padding-bottom: 4px;
		&:hover {
			color: $primary;
			text-decoration: none;
		}
		~ a {
			margin-top: 1rem;
		}
	}
}

.component-image-text-and-link__layout-cards {
	.image-text-and-link-card {
		display: grid;
		grid-template-rows: repeat(3, auto);
		row-gap: 34px;
		padding: 34px;
		background-color: #ffffff;
		box-shadow: 3px 3px 20px #00000029;
		.card-button-wrap {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			column-gap: 32px;
			a {
				&:not(.inverted) {
					@include make-button();
				}
				&.inverted {
					@include make-button(#ffffff, $primary);
				}
			}
		}
	}
}
