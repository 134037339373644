header {
		//STANDARD SIZES
	.carousel.standard {
		$breakpoint-styles: (
			// .carousel-item height, .h1 fontsize, .h2 fontsize
						'xs': (300px,               1.5rem ,      0.8rem         ),
						'sm': (300px,               2rem   ,      1rem           ),
						'md': (400px,               1.8rem   ,    1.25rem        ),
						'lg': (500px,               2.5rem,      1.75rem        ),
						'xl': (600px                                             ),
		);
		@each $breakpoint, $props in $breakpoint-styles {
			@include media-breakpoint-up($breakpoint) {
				.carousel-item {
					height: nth($props, 1);
				}
				@if length($props) >= 2 {
					.h1 {
						@include font-size(nth($props, 2));
						letter-spacing: 5px;

					}
				}
				@if length($props) >= 3 {
					.h2 {
						@include font-size(nth($props, 3));
						letter-spacing: 4.38px;
					}
				}
			}
		}
		.text-side {
			background-color: rgba($primary, 1);
			transition: background-color ease-in-out 0.2s;
		}
		@include media-breakpoint-down(sm) {
			.text-side {
				background-color: rgba($primary, 0.8);
			}
		}
	}
		//LANDING PAGE 1 SIZES
	.carousel.landing-page-1 {
		$breakpoint-styles: (
			// .carousel-item height, .h1 fontsize, .h2 fontsize	.line height
			'xs': (100vh,               1.5rem ,      0.8rem ,		2.6rem),
			'sm': (100vh,               2rem   ,      1rem	 ,		3.6rem),
			'md': (100vh,               3rem   ,      1.25rem,		4rem),
			'lg': (100vh,               3.75rem,      1.75rem,		5.6rem),
			'xl': (100vh,                                     ),
		);
		@each $breakpoint, $props in $breakpoint-styles {
			@include media-breakpoint-up($breakpoint) {
				.carousel-item {
					height: nth($props, 1);
				}
				@if length($props) >= 2 {
					.h1 {
						@include font-size(nth($props, 2));
						letter-spacing: 3.5px;
					}
				}
				@if length($props) >= 3 {
					.h2 {
						@include font-size(nth($props, 3));
					}
				}
			}
		}
		//LINE HEIGHT STUFF
		.carousel-item {
			@include media-breakpoint-up(xs) { p{line-height: 2.6rem} }
			@include media-breakpoint-up(sm) { p{line-height: 3.6rem} }
			@include media-breakpoint-up(md) { p{line-height: 4rem} }
			@include media-breakpoint-up(lg) { p{line-height: 5rem} }
			@include media-breakpoint-up(xl) { p{line-height: 5.6rem} }
		}
	}

	.carousel {
		.carousel-item {
			img {
				height: 100%;
				object-fit: cover;
			}
		}
		.carousel-caption {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: left;
			bottom: auto;
			right: auto;
			a {
				color: $secondary;
				border: solid 1px $secondary;
			}
			p {
				line-height: 5.6rem;
			}
		}
		svg.content-anchor {
			border-radius: 50%;
			-webkit-transition: all 0.15s ease;
			-moz-transition: all 0.15s ease;
			-o-transition: all 0.15s ease;
			transition: all 0.15s ease;
			position: absolute;
			bottom: 5%;
			left: 50%;
			transform: translateX(-50%);
			fill: #00aeef;

			@media screen and (max-width: 767px) {
				display: none;
			}

			&:hover {
				cursor: pointer;
				background-color: rgba(8, 222, 245, 1);

				path:first-of-type {
					display: none;
				}
				path {
					fill: #fff;
				}
			}
		}
		svg {
			//position: absolute;
			//bottom: 5%;
			//left: 50%;

			&:hover {
				cursor: pointer;
			}
		}

	}
}

.stepped-carousel {
	article {
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		transition: all 1s ease;

		&:not(:first-of-type) {
			display: none;
		}
	}
	@include media-breakpoint-up(md) {
		.controls {
			position: absolute;
			// top: 250px;
			top: 60%;
			left: 40%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
	}
	@include media-breakpoint-down(md) {
		.controls {
			text-align: center;
			margin-top: 1.5rem;
		}
	}
}

.carousel-indicators {
	bottom: 0px;
	li {
		width: 13px;
		height: 13px;
		border-radius: 50%;
		margin-right: 10px;
		margin-left: 10px;
	}
}

.bg-light {
	.carousel-indicators li{
		background-color: $secondary;
		opacity: 0.5;
		&.active {
			opacity: 1;
		}
	}
}


.slick-slide {
    outline: none !important;
}

/**
 ----------------------------------------------------------------------
 * Product Carousel
 ----------------------------------------------------------------------
*/
.product-carousel {
	.slick-prev {
		z-index: 2 !important;
		left: 50px !important;			
		padding-bottom: 100px !important;
		display: inline-block !important;
		width: auto !important;
		&:before {
			content: url('/uploads/images/icons/icon-arrow-prev.png') !important;
		}								
	}
	.slick-next {
		z-index: 2 !important;
		right: 50px !important;			
		padding-bottom: 100px !important;
		display: inline-block !important;
		width: auto !important;
		&:before {
			content: url('/uploads/images/icons/icon-arrow-next.png') !important; 
		}
	}
	@include media-breakpoint-down(sm) {
		.slick-next:before, .slick-prev:before {
			position: initial;			
			font-family: slick;
			font-size: 20px;
			line-height: 1;
			color: #fff;
			opacity: .75;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;			
		}
	}
	.product-carousel__product-title {
		font-size: 1.2rem;
	}
	.btn {
		font-family: $heading-font-family;
		font-size: 1.2rem;
	}
	.product-desc {
		flex: 1 0 auto;
	}
}

.tns-nav {
	width: fit-content;
	margin: 6.25rem auto 0;

	& > * + * {
		margin-left: 2.75rem;
	}
	button {
		background-color: $light;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		border: none;
		transition: background-color 0.2s ease-in-out;
		&:hover {
			background-color: darken($light, 10%);
		}
		&.tns-nav-active {
			background-color: $primary;
			&:hover {
				background-color: darken($primary, 10%);
			}
		}
		&:focus {
			outline: none;
		}
	}
}
