.component-links__list {
    .links {
        color: $white;
    }
}

.component-links__buttons-x,
.component-links__buttons-y {
    a {
        @include make-button();
    }
}
