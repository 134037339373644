$aspect-ratio-presets: () !default;
$aspect-ratio-presets: map-merge(
	(
		'square': (1 1),
		'video': (16 9),
	),
	$aspect-ratio-presets,
);

.aspect-none {
	@supports (aspect-ratio: 1/1) {
		aspect-ratio: unset;
	}
	@supports not (aspect-ratio: 1/1) {
		position: static;
		padding-bottom: 0;
		> * {
			position: static;
			height: auto;
			width: auto;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
		}
	}
}

@include range(1, 16) using ($i) {
	.aspect-w-#{$i} {
		@supports (aspect-ratio: 1/1) {
			aspect-ratio: var(--mb-aspect-w) / var(--mb-aspect-h);
		}
		@supports not (aspect-ratio: 1/1) {
			position: relative;
			padding-bottom: calc(var(--mb-aspect-h) / var(--mb-aspect-w) * 100%);
			> * {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
		--mb-aspect-w: #{$i};
	}

	.aspect-h-#{$i} {
		--mb-aspect-h: #{$i};
	}
}

@each $preset-name, $preset-values in $aspect-ratio-presets {
	$w: nth($preset-values, 1);
	$h: nth($preset-values, 2);
	.aspect-#{$preset-name} {
		@supports (aspect-ratio: 1/1) {
			aspect-ratio: #{$w} / #{$h};
		}
		@supports not (aspect-ratio: 1/1) {
			position: relative;
			padding-bottom: $h / $w * 100%;
			> * {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
	}
}
