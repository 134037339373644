
.square-container{
    display: flex;
    flex-wrap: wrap;
    // Square Buttons
    .square {
        position: relative;
        flex-basis: calc(33.333% - 10px);
        margin: 5px;		
        box-sizing: border-box;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        .content {
            position: absolute;
            top: 0; left: 0;
            height: 100%;
            width: 100%;		
            
            .grid-image-link-wrapper{
                .grid-image{
                    .background{
                        display:block;
                    }
                    .background-focus{
                        display:none;
                    }
                }
                &:focus{
                    .grid-image{
                        .background{
                            display:none;
                        }
                        .background-focus{
                            display:block;
                        }
                    }
                }
                .grid-image {
                    height: 60%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                p {
                    height: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }            
        }			
    }    
}
	
// Dark Mobile Buttons
.link-grid-container{
    &.dark-theme {
        .square {
            .content {
                background: $gray-700;
                color: #fff;
                text-align: center;
                text-transform: uppercase;			
                a {
                    color: #fff;	
                    text-decoration: none;
                    &:focus {
                        color: $primary;
                    }							
                }					
            }
    
        } 
    }
    
    // Light Mobile Buttons	
    &.light-theme {
        .square { 
            .content {
                background: $secondary;
                color: #fff;
                text-align: center;
                text-transform: uppercase;
                a {
                    color: #fff;	
                    text-decoration: none;
                    &:focus {
                        color: $primary;					
                    }							
                }				
            }	
        }
    } 	
}
