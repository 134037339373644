.overlay-container {
	position: relative;
	font-weight: 300;

	img {
		// min-height: 400px;
		// height: 400px;
		object-fit: cover;
		object-position: top center;
		max-height: 450px;
	}
	.overlay-image::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: #fff;
	}
	.overlay-content {
		// font-size: 2.5em !important;
		color: $primary;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include media-breakpoint-down(md) {
			width: 100%;
		}

		h1 {
			text-align: center;
		}
	}

	.h1 {
		font-size: 4rem;
		font-weight: inherit;
	}

	a {
		border: solid 2px $primary;
		background: $primary;
		color: #fff;
		font-size: 1.5rem;
		font-weight: 100;
		padding: 0 2.75rem;
		border: 1px solid transparent;
		&:hover {
			background: #fff;
			border: 1px solid $primary;
		}
	}
}

.coloured-overlay {
	mix-blend-mode: multiply;
	&.coloured-overlay-primary {
		background-color: $primary;
	}
	&.coloured-overlay-secondary {
		background-color: $secondary;
	}
	&.coloured-overlay-dark {
		background-color: $dark;
	}
}

.offset-images {
	font-size: 2.5rem;

	img {
		width: 100%;
		height: 100%;
	}
	.offset-images__image-container {
		overflow: hidden;
	}
	&:hover img {
		-webkit-transform: scale(1.1) translateZ(0);
		transform: scale(1.1) translateZ(0);
		transition: -webkit-transform 4s linear;
		transition: transform 6s linear;
		transition: transform 6s linear, -webkit-transform 6s linear;
	}
	.offset-images__btn {
		text-align: center;
		font-size: 1.75rem;
	}
	.offset-images__btn a {
		@media screen and (min-width: 767px) {
			width: 50%;
		}
		font-size: 1.75rem;
	}
	.nav-link:hover {
		transform: scale(1.1);
		-webkit-transition: all 0.25s ease;
		-moz-transition: all 0.25s ease;
		-o-transition: all 0.25s ease;
		transition: all 0.25s ease;
	}
}

.image-text-overlay {
	&[data-type='call-to-action'] {
		.content-wrap {
			width: 75%;
			left: 10%;
			@include media-breakpoint-up(md) {
				width: 50%;
			}
			@include media-breakpoint-up(xl) {
				width: 25%;
				left: 25%;
				transform: translateX(-50%);
			}
		}
	}
	&[data-type='booking'] {
		.darkened-overlay {
			backdrop-filter: brightness(0.45);
		}
	}
	&[data-type='banner'], &[data-type='quote-with-heading'] {
		img {
			max-height: 650px;
			object-fit: cover;
		}
		.darkened-overlay {
			height: 400px;
			background: transparent linear-gradient(0deg, #000000C7 0%, transparent 100%) 0 0 no-repeat padding-box;
		}
		&:after {
			content: '';
			height: 20px;
			width: 100vw;
			background-color: $secondary;
			display: block;
		}
	}
	&[data-type='links'] {
		.darkened-overlay {
			opacity: 0.61;
			background: linear-gradient(90deg, #000000 0%, transparent 100%);
		}
		img {
			max-height: 860px;
			object-fit: cover;
			object-position: center;
			@include media-breakpoint-down(md) {
				max-height: 400px;
				direction: rtl;
			}
		}
		a {
			background-color: #000000bf;
		}
	}
	&[data-type='quote'], &[data-type='quote-with-heading'] {
		.text-wrapper {
			background-color: rgba(#000000, 0.72);
			> div:after {
				content: '\201C';
				font-size: 100px;
				line-height: 1.2;
				position: absolute;
				top: 0;
				// A &ldquo; is 30px wide at 100px font size.
				// The text has a width of 600px.
				// 600 / 2 - 30 / 2 = 285
				left: 285px;
			}
		}
	}
}
