.cat-accordion {    
    .cat-accordion-item {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: $white;
        border: solid $secondary 2px;
        text-decoration: none !important;                                   
        h4 {
            background: none;
            font-family: "Futura Bold";
            font-size: 1.25rem;
            position:relative;
            color: $secondary;
            margin-bottom:0;
            @media (max-width: 350px) {
                font-size: 1rem;
            }
            &:after {
                position: absolute;                
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                content: ' ';
                background-image: url(/images/icons/png/icon-right.png);
                background-size: contain;
                height: 10px;
                width: 10px;                
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .cat-accordion-product-link{
            display: block;
            margin-bottom: 1.5rem;
            &:hover {
                text-decoration: none;
                p,small{
                    color: $primary;
                }
                .cat-accordion-product  .cat-accordion-product-icon {      
                    background-image: url(/images/icons/png/icon-right.png);
                }
            }
            &:last-of-type{
                margin-bottom: 0;
            }
            .cat-accordion-product {  
                font-family: "Futura";
                position: relative;            
                p {
                    font-size: 1rem;
                    padding-right: 1.5rem;
                    @media (max-width: 350px) {
                        font-size: 0.8rem;
                        padding-right: 2rem;
                    }
                }
                .cat-accordion-product-icon {                                
                    position: absolute;                
                    right: 0;                                
                    background-image: url(/images/icons/png/icon-right.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 25px;
                    width: 25px;                
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
    
            }
        }        
        &.active {        
            background-color: $secondary;
            h4, a {
                color: $white;
            }
            h4.cat-accordion-title {
                margin-bottom: 1.5rem;
            }
            .cat-accordion-header{
                &:after{                    
                    background-image: url(/images/icons/png/icon-down.png);
                }
            }
        }
        .products.collapsing, .products.show{
            h4, a {
                color: $white;
            }
            h4.cat-accordion-title {
                margin-bottom: 1.5rem;
            }
            .cat-accordion-header{
                &:after{                    
                    background-image: url(/images/icons/png/icon-down.png);
                }
            }
        }
    }
}