.page-hero {
    position: relative;
    height: 100vh;
    padding: 0;
    &.inner-page-hero {
        height: 16rem;
        .hero-image-wrapper .hero-text {
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto !important;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                max-height: 10rem;
                margin-bottom: 0;
            }
        }
    }
    .page-header {
        position: relative;
        z-index: 1;
    }
    .hero-image-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: inherit;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        // &::after {
        //     position: absolute;
        //     top: 0;
        //     display: block;
        //     width: 100%;
        //     height: 100%;
        //     content: '';
        //     background: linear-gradient(180deg, rgba(17,21,27,0) 90%, rgba(17,21,27,1) 100%);
        // }
        .hero-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
        .hero-text {
            position: absolute;
            top: 20vh;
            width: 100%;
            &.inner-page-text {
                @include media-breakpoint-up(lg) {
                    width: 30rem !important;
                    margin-left: 2rem;
                    h2 {
                        margin-bottom: 0 !important;
                    }
                    .title {
                        transform: scale(70%);
                    }
                }
            }
            img {
                display: block;
                margin: 0 auto 10vh;
                max-width: 30rem;
            }
            h2 {
                font-size: 2rem;
            }
        }
        .hero-arrow {
            position: absolute;
            bottom: 5vh;
            left: 50%;
            width: 3rem;
            transform: translateX(-50%);
        }
    }
}