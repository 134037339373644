.circle-point-wrapper {
	@include media-breakpoint-down(md) {
		height: auto !important;
	}
}

.circle-points__circle {
	border-radius: 50%;
	aspect-ratio: 1 / 1;
	@include media-breakpoint-up(md) {
		position: absolute;
	}
	@include media-breakpoint-down(md) {
		margin: 0 auto 50px auto;
	}
	position: initial;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	img + div {
		top: 70%;
		transition: top 0.2s ease-in-out;
		background-color: #ffffff;
		padding: 0 1rem;
		p:first-of-type {
			padding: 1.5rem 1rem;
			text-transform: uppercase;
			font-weight: 400;
			& + span {
				display: block;
				width: 33%;
				height: 1px;
				margin: 0 auto 1rem;
				background-color: $dark;
				visibility: hidden;
			}
		}
		p {
			text-align: center;
		}
	}
	&:hover img + div {
		top: 0;
		p:first-of-type + span {
			visibility: visible;
		}
	}
}
