* {
	border: 0 solid $primary;
}

@function opposite-side($side) {
	@return if($side == 'left', 'right', 'bottom');
}

$sides: ('x': 'left', 'y': 'top');
@include each-breakpoint using ($prefix) {
	@each $side, $prop in $sides {
		$opposite-side: opposite-side($prop);
		@for $i from 0 through 4 {
			$class: $i * 2;
			$width: $class * 1px;

			.#{$prefix}divide-#{$side}-#{$class} > :not([hidden]) ~ :not([hidden]) {
				--mb-divide-#{$side}-reverse: 0;
				border-#{$opposite-side}-width: calc(#{$width} * var(--mb-divide-#{$side}-reverse));
				padding-top: calc(1 - var(--mb-divide-#{$side}-reverse));
				border-#{$prop}-width: calc(#{$width} * calc(1 - var(--mb-divide-#{$side}-reverse)));
			}
		}

		.#{$prefix}divide-#{$side} > :not([hidden]) ~ :not([hidden]) {
			--mb-divide-#{$side}-reverse: 0;
			border-#{$opposite-side}-width: calc(1px * var(--mb-divide-#{$side}-reverse));
			border-#{$prop}-width: calc(1px * calc(1 - var(--mb-divide-#{$side}-reverse)));
		}

		.#{$prefix}divide-#{$side}-reverse > :not([hidden]) ~ :not([hidden]) {
			--mb-divide-#{$side}-reverse: 1;
		}
	}

	.#{$prefix}divide-inherit > :not([hidden]) ~ :not([hidden]) {
		border-color: inherit;
	}

	.#{$prefix}divide-current > :not([hidden]) ~ :not([hidden]) {
		border-color: currentColor;
	}

	.#{$prefix}divide-transparent > :not([hidden]) ~ :not([hidden]) {
		border-color: transparent;
	}

	@each $colour, $value in $theme-colors {
		.#{$prefix}divide-#{$colour} > :not([hidden]) ~ :not([hidden]) {
			border-color: $value;
		}
	}

	.#{$prefix}divide-solid > :not([hidden]) ~ :not([hidden]) {
		border-style: solid;
	}

	.#{$prefix}divide-dashed > :not([hidden]) ~ :not([hidden]) {
		border-style: dashed;
	}

	.#{$prefix}divide-dotted > :not([hidden]) ~ :not([hidden]) {
		border-style: dotted;
	}

	.#{$prefix}divide-double > :not([hidden]) ~ :not([hidden]) {
		border-style: double;
	}

	.#{$prefix}divide-none > :not([hidden]) ~ :not([hidden]) {
		border-style: none;
	}
}