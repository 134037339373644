
// IE 10 and 11
@media screen and (min-width:0\0){
    @import "ms-forms";
}

//Edge Styles
@supports (-ms-ime-align:auto) {
    @import "ms-forms";
}
