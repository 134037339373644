@import "utilities/aspect-ratio";
@import "utilities/border-radius";
@import "utilities/dividers";
@import "utilities/flex-positioning";
@import "utilities/grid";
@import "utilities/height";
@import "utilities/layout";
@import "utilities/max-width";
@import "utilities/min-width";
@import "utilities/object-fit";
@import "utilities/spacing";
@import "utilities/tracking";
@import "utilities/typography";
@import "utilities/leading"; // Has to go after typography
@import "utilities/width";
@import "utilities/z-index";

.overflow-hidden {
	overflow: hidden;
}

.cursor-pointer {
	cursor: pointer;
}

.letter-spacing-none {
	letter-spacing: 0 !important;
}

.text-white {
	*,
	a {
		color: #fff;
	}
}

.text-alt-grey {
	color: $alt-text-grey;
	*,
	a {
		color: $alt-text-grey;
	}
}

.bg-alt-grey {
	background-color: $alt-text-grey;
}

.d-grid {
	display: grid;
}
.z-index {
	z-index: 2;
}
.w-md-75 {
	@media screen and (min-width: 767px) {
		width: 75%;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.arrow-down {
	border-radius: 50%;
	background-color: #fff;
	color: red;
	max-width: 256px;
	max-height: 256px;
	background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNCAzMi42MzQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNCAzMi42MzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMTYuMzE3LDMyLjYzNGMtMC4yNzYsMC0wLjUtMC4yMjQtMC41LTAuNVYwLjVjMC0wLjI3NiwwLjIyNC0wLjUsMC41LTAuNXMwLjUsMC4yMjQsMC41LDAuNXYzMS42MzQgICBDMTYuODE3LDMyLjQxLDE2LjU5MywzMi42MzQsMTYuMzE3LDMyLjYzNHoiIGZpbGw9IiMwMDAwMDAiLz4KCTxwYXRoIGQ9Ik0xNi4zMTUsMzIuNjM0TDE2LjMxNSwzMi42MzRjLTAuMTMzLDAtMC4yNi0wLjA1My0wLjM1NC0wLjE0NkwzLjQyOCwxOS45NTFjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDcgICBzMC41MTItMC4xOTUsMC43MDcsMGwxMi4xNzksMTIuMTgzbDEyLjE4NC0xMi4xODNjMC4xOTUtMC4xOTUsMC41MTItMC4xOTUsMC43MDcsMHMwLjE5NSwwLjUxMiwwLDAuNzA3TDE2LjY2OCwzMi40ODcgICBDMTYuNTc0LDMyLjU4MSwxNi40NDgsMzIuNjM0LDE2LjMxNSwzMi42MzR6IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

/* Effects */
.hover-shadow:hover {
	border-left: solid 20px transparent;
	border-right: solid 20px transparent;
	border-bottom: solid 20px #000;
	filter: drop-shadow(4px 4px 5px #acacac);
	transition: all 0.2s ease;
	transform: scale(1.025);
}

@media (min-width: 768px) {
	.collapse.dont-collapse-sm {
		display: block;
		height: auto !important;
		visibility: visible;
	}
}

.pointer-events-all {
	pointer-events: all !important;
}

.pointer-events-none {
	pointer-events: none !important;
}

.bg-body {
	background-color: $body-bg;
}
