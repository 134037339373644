.grid-carousel-outer-wrapper {
	position: relative;
	.controls {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		z-index: 50;
		button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: $secondary;
			background: none;
			border: 0;
			&:hover {
				color: darken($secondary, 10%);
			}
			&.grid-carousel-previous {
				left: -5rem;
			}
			&.grid-carousel-next {
				right: -5rem;
			}
		}
	}
}
