$widths: (
	'0': 0rem,
	'none': none,
	'xs': 20rem,
	'sm': 24rem,
	'md': 28rem,
	'lg': 32rem,
	'xl': 36rem,
	'2xl': 42rem,
	'3xl': 48rem,
	'4xl': 56rem,
	'5xl': 64rem,
	'6xl': 72rem,
	'7xl': 80rem,
	'full': 100%,
	'min': min-content,
	'max': max-content,
	'fit': fit-content,
	'prose': 65ch,
);

@include each-breakpoint using ($prefix) {
	@each $width, $value in $widths {
		.#{$prefix}max-w-#{$width} {
			max-width: $value;
		}
	}

	@each $breakpoint, $value in $grid-breakpoints {
		@if $value > 0 {
			.#{$prefix}max-w-screen-#{$breakpoint} {
				max-width: $value;
			}
		}
	}
}
