/********************************
 *  CSS GRID UTILITY CLASSES
 *******************************/

$classes: (
	'gap-': 'gap',
	'gap-x-': 'column-gap',
	'gap-y-': 'row-gap',
);
@include each-breakpoint using ($prefix) {
	.#{$prefix}grid {
		display: grid;
	}

	.#{$prefix}inline-grid {
		display: inline-grid;
	}

	@include range(1, 12) using ($i) {
		.#{$prefix}grid-cols-#{$i} {
			grid-template-columns: repeat($i, minmax(0, 1fr));
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}grid-cols-#{$i}-auto {
			grid-template-columns: repeat($i, auto);
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}grid-rows-#{$i} {
			grid-template-rows: repeat($i, minmax(0, 1fr));
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}grid-rows-#{$i}-auto {
			grid-template-rows: repeat($i, auto);
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}col-span-#{$i} {
			grid-column: span $i / span $i;
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}col-start-#{$i} {
			grid-column-start: $i;
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}col-end-#{$i} {
			grid-column-end: $i;
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}row-span-#{$i} {
			grid-row: span $i / span $i;
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}row-start-#{$i} {
			grid-row-start: $i;
		}
	}
	@include range(1, 12) using ($i) {
		.#{$prefix}row-end-#{$i} {
			grid-row-end: $i;
		}
	}

	.#{$prefix}grid-cols-none {
		grid-template-columns: none;
	}

	.#{$prefix}grid-rows-none {
		grid-template-rows: none;
	}

	.#{$prefix}grid-flow-row {
		grid-auto-flow: row;
	}

	.#{$prefix}grid-flow-col {
		grid-auto-flow: column;
	}

	.#{$prefix}grid-flow-row-dense {
		grid-auto-flow: row dense;
	}

	.#{$prefix}grid-flow-col-dense {
		grid-auto-flow: column dense;
	}

	@each $class, $prop in ('cols': 'columns', 'rows': 'rows') {
		@each $vClass, $value in ('auto': auto, 'min': min-content, 'max': max-content, 'fr': 1fr) {
			.#{$prefix}auto-#{$class}-#{$vClass} {
				grid-auto-#{$prop}: $value;
			}
		}
	}

	@each $class, $prop in $classes {
		.#{$prefix}#{$class}0 {
			#{$prop}: 0;
		}

		.#{$prefix}#{$class}px {
			#{$prop}: 1px;
		}

		.#{$prefix}#{$class}ch {
			#{$prop}: 1ch;
		}

		@include each-size using ($i, $value) {
			.#{$prefix}#{$class}#{$i} {
				#{$prop}: $value;
			}
		}
	}

	@for $i from 1 through 12 {
		.#{$prefix}order-#{$i} {
			order: $i;
		}
	}
}
