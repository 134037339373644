.image-grid-wrapper {
    + .image-grid-wrapper {
        margin-top: 8px;
    }
}

.image-grid {
    display: grid;

    .image-grid-item {
        position: relative;
        overflow: hidden;
        border-radius: 0;
        transition: border-radius 0.3s ease-in-out;

        .image-grid-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transition: background-color 0.3s ease-in-out;

            &__title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 124px;
                background: transparent linear-gradient(180deg, #FFFFFF00 0%, #7E7E7E81 0%, #000000D5 100%) 0 0 no-repeat padding-box;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #ffffff;
                padding: 0 1.625rem;
                transition: $transition-collapse;
                p {
                    font-family: Montserrat, $paragraph-font-family;
                    font-weight: 400;
                    @include font-size(24px);
                    transition: font-size 0.35s ease;
                }
                img, svg {
                    height: inherit;
                    max-width: 242px;
                }
            }

            &__link {
                position: absolute;
                bottom: 12%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: $transition-fade;
                a {
                    color: #ffffff;
                }
            }

            &:hover {
                padding: 0 0.25rem;
                .image-grid-text__title {
                    height: 100%;

                    p {
                        @include font-size(35px);
                    }
                }
                .image-grid-text__link {
                    opacity: 1;
                }
            }

            .image-grid-text-content,
            .profile-title {
                position: absolute;
                top: 75%;
                width: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                opacity: 0;
                color: white;
                transition: opacity 0.3s ease-in-out;
                @include media-breakpoint-down(md) {
                    top: 85%;
                }
            }

            /* &:hover,
            &:focus {
                background-color: rgba($primary, 0.4);

                h3 {
                    text-align: center;
                    left: 50%;
                    bottom: 60%;
                    @include media-breakpoint-down(md) {
                        bottom: 50%
                    }
                }

                .image-grid-text-content,
                .profile-title {
                    opacity: 1;
                }
            } */
        }

        .image-grid-image {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        /* &:hover {
            border-radius: 0 3rem 0 0;
        } */
    }
}
