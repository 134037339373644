.kenburns {
	animation: kenburns 6s ease;
	-ms-animation: kenburns 6s ease;
	-webkit-animation: kenburns 6s ease;
	-moz-animation: kenburns 6s ease;
}

@keyframes kenburns {
	0% {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		transform: scale(1);
		-ms-transform: scale(1) rotate(0.01deg);
		/* IE 9 */
		-webkit-transform: scale(1);
		/* Safari and Chrome */
		-o-transform: scale(1);
		/* Opera */
		-moz-transform: scale(1) rotate(0.01deg);
		/* Firefox with degree fix to prevent animation shaking */
	}
	100% {
		transform: scale(1.1);
		-ms-transform: scale(1.1);
		/* IE 9 */
		-webkit-transform: scale(1.1);
		/* Safari and Chrome */
		-o-transform: scale(1.1);
		/* Opera */
		-moz-transform: scale(1.1);
		/* Firefox */
	}
}

@keyframes kenburns-reverse {
	0% {
		transform: scale(1.1);
		-ms-transform: scale(1.1);
		/* IE 9 */
		-webkit-transform: scale(1.1);
		/* Safari and Chrome */
		-o-transform: scale(1.1);
		/* Opera */
		-moz-transform: scale(1.1);
		/* Firefox */
	}
	100% {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		transform: scale(1);
		-ms-transform: scale(1) rotate(0.01deg);
		/* IE 9 */
		-webkit-transform: scale(1);
		/* Safari and Chrome */
		-o-transform: scale(1);
		/* Opera */
		-moz-transform: scale(1) rotate(0.01deg);
		/* Firefox with degree fix to prevent animation shaking */
	}
}

