body {
	background-size: 100%;
	background-repeat: repeat-y;
}
.separator-line {
	background-image: url('');
	background-position: center;
	background-size: contain;
	padding: 1.5rem 0;
}

.round-image {
	border-radius: 50%;
	overflow: hidden;
}

.testimonial-strip {
	background: rgba(221, 221, 223, 0.6);
}

.border-thick {
	border-width: 0.25rem !important;
}

.object-fit-cover {
	object-fit: cover;
}

.cursor-normal {
	cursor: auto !important;
}