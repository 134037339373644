.tabbed-content {
	.nav-tabs {
		a {
			color: $primary;
			text-align: center;
			text-transform: uppercase;
			padding: 0.75rem 0.5rem;
			&.active {
				color: #fff;
				background: $secondary;
				a {
					color: #fff;
				}
			}
		}

		display: flex;
		align-items: stretch;
		list-style: none;

		li {
			display: flex;
			flex: 1;
			align-items: stretch;

			a {
				flex: 1;
			}
		}
	}
}



// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(md) {
	.tab-content .tab-pane {
		display: block !important;
		opacity: 1;
	}
	.nav.nav-tabs {
		display: none !important;
	}
	.form-inline {
		-ms-flex-flow: row;
		flex-flow: row;
	}
}

.product-gallery {
	img {
		object-fit: cover;
	}
	.thumbs img {
		width: 100%;
	}
}
.product-specs {
	p:first-of-type {
		font-weight: 600;
	}
}
