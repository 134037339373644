#navigation {
    &.landing-page-1 {
        background-color: rgba(113,125,108,0);
        top: 0;
        width: 100%;
        position: absolute;
        &.scrolled {
            background-color: rgba($body-color, .8);
        }
        &.mirror {
            display: none;
        }
        a:not([href]), a {
            color: $white;
            @include media-breakpoint-down(md) {
                &.opened {
                    color: #000 !important;
                    z-index: 999;
                }
            }
        }
    }
    background-color: #ffffff;
    transition: background-color 0.2s ease-in-out;
    z-index: 999;
    position: relative;

    &.mirror {
        display: none;
    }

    & > div {
        padding: 3rem;
    }

    &.scrolled {
        background-color: #ffffffbf;
        position: fixed;
        top: 0;
        width: 100%;
        #admin-bar-front ~ & {
            top: 32px;
        }
        & + .mirror {
            display: block;
        }
        & > div {
            padding: 1rem 3rem;
        }
    }

    #logo-link {
        img {
            max-height: 137px;
            @include media-breakpoint-down(md) {
                max-height: 70px;
            }
        }
    }

    .nav-toggle {
        z-index: 50;
        path:nth-of-type(1) {
            transition: opacity 0.2s ease-in-out;
        }
        path:not(:nth-of-type(1)) {
            transition: transform 0.2s ease-in-out;
        }
        &.opened {
            position: fixed;
            top: 3rem;
            right: 3rem;
            svg {
                overflow: visible;
            }
            path:nth-of-type(1) {
                opacity: 0;
            }
            path:nth-of-type(2) {
                transform: rotate(45deg) translate(-3px, -8px);
            }
            path:nth-of-type(3) {
                transform: rotate(-45deg) translate(-25px, -5px);
            }
        }
    }
    @include media-breakpoint-down(md) {
        #nav-toggle.opened {
            svg {
                color: $body-color !important;
            }
        }
        #primary-navigation-items,
        #navigation-items {
            position: fixed;
            top: 0;
            --nav-width: 400px;
            right: calc(-1 * var(--nav-width));
            width: var(--nav-width);
            height: 100vh;
            transition: right 0.2s ease-in-out;
            flex-flow: column;
            background-color: #ffffff;
            z-index: 10;
            display: flex;

            &.open {
                padding-top: 5rem;
                right: 0;
                li a {
                    &:not(.btn) {
                        color: $body-color !important;
                    }
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
            li {
                position: relative;
            }

            // li {
            //     position: relative;
            // }

            // li ul {
            //     display: none;
            //     position: absolute;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     background-color: lighten($dark, 5%);
            //     border: 2px solid #000000;
            //     padding: 1rem;
            //     width: calc(var(--nav-width) / 2);
            //     text-align: center;
            //     z-index: 5;
            // }

            li:hover ul, li:focus ul,
            li a:focus ~ ul {
                display: block;
            }
        }
    }
}

.nav-layout-banner {
	ul {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 3rem;
		margin-bottom: 3rem;

		li {
			font-weight: 300;

			a {
				text-decoration: none;
				&:hover {
					font-weight: 400;
				}
			}
		}
	}
}