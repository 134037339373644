.product-single {
	.product-single__banner {
		img {
			max-height: 500px;
			position: relative;
		}
		img:after {
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			position: absolute;
		}
	}
}


.product-catalogue{
	form{
		label{
			color: #A7A9AC;
		}
	}
}

.show-child-on-hover {
	&:not(:hover) {
		.show-on-hover {
			display: none !important;
		}
	}
}
.accessory-hover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	font-size: 0.85rem;
	text-align: center;
}

.tab-pane {
	button {
		text-transform: uppercase;
		font-weight: bold;
		&::after {
			position: absolute;
			right: 10%;
			content: "▼";
			font-size: 0.9rem;
		}
		&[aria-expanded="true"] {
			margin-bottom: 1rem;
			&::after {
				content: "▲";
				font-size: 0.9rem;
			}
		}
		&:not([aria-expanded="true"]) {
			color: $primary !important;
			background: white !important;
			border-top: 1px solid $gray-100 !important;
		}

	}
	&:last-of-type button:not([aria-expanded="true"]) {
		border-bottom: 1px solid $gray-100 !important;
	}
}