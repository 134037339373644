.modal-btn {
	white-space: nowrap;
	img, svg {
		min-height: 30px;
		max-height: 40px;
	}
}

.modal-component {
	background-color: #000000bf;
	z-index: 999;
	.modal-close {
		--modal-close-size: 45px;
		--close-svg-size: 25px;
		width: var(--modal-close-size);
		height: var(--modal-close-size);
		border-radius: 100%;
		background-color: #ffffff;
		right: calc(0px - var(--modal-close-size) / 2);
		top: calc(0px - var(--modal-close-size) / 2);
		cursor: pointer;
		svg {
			width: var(--close-svg-size);
			height: var(--close-svg-size);
		}
	}
}
