[data-type=ComponentUniversalGrid] {
	> div > div > div { // > grid > item > item-wrapper
		height: 100% !important;
	}
	.tns-outer, .tns-ovh, .tns-inner, .tns-slider, .tns-slider * {
		height: 100% !important;
	}
	.tns-outer {
		position: relative;
		.tns-nav {
			position: absolute;
			bottom: 0;
			width: 100%;
			margin: 0 0 1rem;
			text-align: center;
		}
	}
}

.universal-grid-text-item {
	padding: 1rem;
}

.universal-grid-text-overlay-item {
	.overlay-background {
		opacity: .7;
		&.bg-black {
			background-color: #000000;
		}
	}
	& > :last-child {
		padding: 1rem;
	}
}
