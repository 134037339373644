.scrolling-testimonials {
    max-height: 350px;
    overflow-y: scroll;
    overflow-y: auto;
    @include media-breakpoint-down(md) {
        max-height: unset;
        overflow-y: auto;
    }
    .testimonial {
        padding: 0.5rem 0;
        .testimonial-description * {
            margin: 0;
        }
        + .testimonial {
            border-top: 1px solid $gray-100;
        }
    }
}