$selectors: (
	'inset': ('top', 'right', 'bottom', 'left'),
	'inset-x': ('right', 'left'),
	'inset-y': ('top', 'bottom'),
	'top': ('top'),
	'right': ('right'),
	'bottom': ('bottom'),
	'left': ('left'),
);

@each $class, $props in $selectors {
	.#{$class}-0 {
		@each $prop in $props {
			#{$prop}: 0;
		}
	}
}

@each $class, $props in $selectors {
	.#{$class}-px {
		@each $prop in $props {
			#{$prop}: 1px;
		}
	}
}

@include each-size using ($i, $value) {
	@each $class, $props in $selectors {
		.#{$class}-#{$i} {
			@each $prop in $props {
				#{$prop}: $value;
			}
		}
	}
}
