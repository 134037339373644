.revolving-carousel {
	.row {
		$size: 11vw;
		display: grid;
		grid-gap: 3rem;
		position: relative;
		.revolving-carousel__item {
			width: $size;
			height: $size;
			border-radius: 50%;
			background-color: #ffffff;
			box-shadow: #00000012 0 0 20px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-width: 75%;
				max-height: 100px;
			}
		}
		@include media-breakpoint-down(xl) {
			.revolving-carousel__item img {
				max-height: 75px;
			}
		}
		@include media-breakpoint-down(md) {
			$size: 15vw;
			.revolving-carousel__item {
				width: $size;
				height: $size;
				img {
					max-height: 40px;
				}
			}

		}
	}
}
