@import "floating_labels";

form {
	margin-block-end: 0;
	.form-control {
		border: none;
		background-color: $light;
	}
}

form {
	label {
		font-family: $paragraph-font-family;
	}

	//input[type="submit"] {
	//	border-radius: 0;
	//	&:not(.btn-light) {
	//		background-color: $primary;
	//		&:hover, &:active, &:focus {
	//			background-color: $primary;
	//		}
	//	}
	//}

	input:not([type="submit"]),
	textarea,
	select.form-control {
		border-radius: 0 !important;
	}

	.input-row input:focus+label::before,
	.input-row input:active+label::before {
		opacity: 0;
		/* To visually hide the label's pseudoelement */
		pointer-events: none;
		/* To prevent any cursor-based interaction */
	}

	input[type=radio] {
		+label {

			.form-border,
			&.form-border {
				border: transparent 2px solid;
			}
		}

		&:checked+label {

			.form-border,
			&.form-border {
				border: $primary 2px solid;
			}
		}
	}


	textarea {
		resize: none;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		background-position: right 50%;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
		padding: 0.5em;
		padding-right: 1.5em;

		@media all and (-ms-high-contrast: none),
		(-ms-high-contrast: active) {
			background-image: none;
		}
	}

	input:not([type="checkbox"]):not([type="radio"]):not(.btn-square),
	select.form-control:not([size]):not([multiple]),
	&#searchFormSidebar {
		// height: 3.25rem !important;
		height: 3.25rem;
	}


}

.input-gradient {
	padding: 0.5rem 1rem;
	height: auto !important;
	color: #fff !important;
}

.smlInput {
	width: 75px;
	font-size: 16px;
	padding-top: 2px !important;
	max-height: 2.4rem !important;

}

.smlLabel {
	font-size: 1.1rem;
	margin-top: -13px;
	display: block;
}



.transparent-inputs {

	input:not([type="submit"]),
	textarea,
	select.form-control {
		background: none;
	}
}

.tiny-check {
	display: inline-block;
	vertical-align: top;
}

.radio-inline-q input:not([type="checkbox"]) {
	height: auto !important;

}

.header-search {
	svg {
		pointer-events: none;

		@media all and (-ms-high-contrast: none),
		(-ms-high-contrast: active) {
			transform: translateY(-50%);
		}
	}
}


.form-check-input {
	margin-top: 0;

	+label {
		margin-bottom: 0;
	}
}

.alert.alert-danger ul {
	margin: 0;
}

@include media-breakpoint-down(md) {
	.form-label-group {
		label:not(.multi-line-label):not(.dropdown-label) {
			word-wrap: break-word;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		label.dropdown-label:not(.multi-line-label) {
			word-wrap: break-word;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.form-label-group {
	label {
		pointer-events: none
	}
}

.get-in-touch {
	background-color: rgba($primary, 0.075);
	.container {
		background: linear-gradient(180deg, rgba($primary, 0.075) 0%, rgba($primary, 0) 100%); 
	}
}

//CUSTOM CHECKBOXES
.custom-control.custom-checkbox {
	.custom-control-label {
		&::before {
			border-radius: 0px;
		}
	}
	.custom-control-input {
		&:checked {
			background-color: red;
		}
	}
}

.custom-control {
	// .custom-control-label {}

	.custom-control-input:checked~.custom-control-label::after {
		//background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M20.285 2L9 13.567 3.714 8.556 0 12.272 9 21 24 5.715z'/%3E%3C/svg%3E");
		//background-image: url('#{$static-files-path}/down-arrow.png');
		background-image: none !important;
	}

	.custom-control-input:checked~.custom-control-label::before {
		background-image: none !important;
		width: 20px;
		height: 20px;
	}

	.custom-control-label::after, .custom-control-label::before {
		height: 20px !important;
		width: 20px !important;
		top: 0.10rem;
		left: -2.5rem;
	}

}

//LIGHT BACKGROUND FORM
.bg-light {
	form {
		.form-control {
			background-color: white;
		}
	}
}