$classes: (
	'auto': auto,
	'start': flex-start,
	'end': flex-end,
	'center': center,
	'between': space-between,
	'around': space-around,
	'evenly': space-evenly,
	'stretch': stretch,
);
$types: (
	'justify': 'justify-content',
	'justify-items': 'justify-items',
	'justify-self': 'justify-self',
	'content': 'align-content',
	'items': 'align-items',
	'self': 'align-self',
	'place-content': 'place-content',
	'place-items': 'place-items',
	'place-self': 'place-self',
);

@function is-valid($class, $type: none) {
	@if $type == 'justify' or $type == 'content' {
		@if $class == 'stretch' {
			@return false;
		}
	}
	@if not ($type == 'justify' or $type == 'content' or $type == 'place-content') {
		@if $class == 'between' or $class == 'around' or $class == 'evenly' {
			@return false;
		}
	}
	@if not ($type == 'justify-self' or $type == 'self' or $type == 'place-self') {
		@if $class == 'auto' {
			@return false;
		}
	}
	@return true;
}

@include each-breakpoint using ($prefix) {
	@each $type, $prop in $types {
		@each $class, $value in $classes {
			@if is-valid($class, $type) {
				.#{$prefix}#{$type}-#{$class} {
					#{$prop}: $value;
				}
			}
		}
	}
}
