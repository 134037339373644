#error_search_wrapper {
    svg#Layer_1 {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }
    input {
        border-radius: .4rem!important;
        border: 1px solid #adb5bd;
        font-size: .9rem;
        height: 2.75rem !important;
        padding: 5px 45px;
    }
}