.image-links-component {
	&[data-layout=overlay] {
		a {
			div:last-of-type {
				opacity: 0;
				background-color: #00000080;
				transition: $transition-fade;
			}
			&:hover, &:focus {
				div:last-of-type {
					opacity: 1;
				}
			}
		}
	}
}