.cart-table, .discount-code-table {
    th, td {
        vertical-align: middle;
    }
    input[type="submit"], button {
        line-height: 2rem;
        padding: 0;
        border: none;
    }
    .btn-square {
        width: 2rem;
        height: 2rem;
    }
    span.qty-display {
        width: 3rem;
        display: inline-block;
        text-align: center;
    }
    small {
        display: flex;
        align-items: center;
        + small {
            margin-top: 0.5rem;
        }
        input {
            min-width: 2rem;
        }
    }
}
.back-button {
    padding-top: 0.765rem;
    padding-bottom: 0.765rem;
}

@include media-breakpoint-down(sm) {
    .cart-table {
        tr {
            display: block;
            td {
                border: 0;
            }
            &:first-child {
                display: none;
            }
            &[item] {
                border: 1px solid #dee2e6;
                margin-bottom: 1.5rem;
                td {
                    display: flex;
                    &::before {
                        width: 8rem;
                        margin-right: 1rem;
                    }
                    &:nth-child(1) {
                        flex-wrap: wrap;
                        .item-name {
                            width: calc(100% - 9rem);
                        }
                        .item-codes {
                            width: 100%;
                        }
                        &::before {
                            display: block;
                            content: 'Product Name';
                        }
                    }
                    &:nth-child(2)::before {
                        display: block;
                        content: 'Quantity';
                    }
                    &:nth-child(3)::before {
                        display: block;
                        content: 'Price';
                    }
                    &:nth-child(4)::before {
                        display: block;
                        content: 'Remove';
                    }
                }
            }
            &[item-count] {
                display: flex;
                td {
                    display: flex;
                    align-items: center;
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                }
                td:nth-of-type(1), td:nth-of-type(2) {
                    display: none;
                }
                td:nth-of-type(3) {
                    width: 5.2rem;
                }
            }
            &[subtotal], &[total] {
                display: flex;
                td {
                    display: flex;
                    align-items: center;
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                }
                td:nth-of-type(1) {
                    display: none;
                }
                td:nth-of-type(2) {
                    flex-direction: column;
                    width: calc(100% - (5.2rem + 5rem));
                    order: 4;
                }
                td:nth-of-type(3) {
                    width: 5.2rem;
                }
                td:nth-of-type(4) {
                    width: 5rem;
                }
            }
        }
        .item-row {
            display: table-row;
            td {
                border: 0;
                width: 25%;
                &:first-child {
                    width: 50%;
                }
            }
        }
        .total-row {
            display: table-row;
            border-top: 1px solid #dee2e6;
            th, td {
                border: 0;
                width: 25%;
            }
            td:first-of-type {
                width: 50%;
            }
        }
    }
    .discount-code-table {
        tr {
            border: 1px solid #dee2e6;
        }
        th, td {
            display: block;
            border: 0;
        }
    }
}