$selectors: (
	'none': 0,
	'sm': 0.125rem,
	'': 0.25rem,
	'md': 0.375rem,
	'lg': 0.5rem,
	'xl': 0.75rem,
	'2xl': 1rem,
	'3xl': 1.5rem,
	'full': 9999px,
);

$sides: (
	't': ('top-left', 'top-right'),
	'r': ('top-right', 'bottom-right'),
	'b': ('bottom-left', 'bottom-right'),
	'l': ('top-left', 'bottom-left'),
	'tl': ('top-left'),
	'tr': ('top-right'),
	'br': ('bottom-right'),
	'bl': ('bottom-left'),
);

@each $class, $value in $selectors {
	.rounded#{prefix($class)} {
		border-radius: $value !important;
	}
}

@each $side, $props in $sides {
	@each $prop in $props {
		@each $class, $value in $selectors {
			.rounded-#{$side}#{prefix($class)} {
				border-#{$prop}-radius: $value !important;
			}
		}
	}
}
