.column-carousel {
	.slick-next,
	.slick-prev {
		z-index: 2;
		top: 50%;		
		@include media-breakpoint-down(sm) {
			top: 37%;
		}
	}
	.slick-next {
		right: 2%;
	}
	.slick-prev {
		left: 2%;
	}

	.column-carousel-image-wrapper {
		@include media-breakpoint-up(md) {
			max-height: 600px;
		}
		@include media-breakpoint-down(md) {
			max-height: 400px;
		}

		width: 100%;
		height: 100%;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			visibility: visible;
			@include media-breakpoint-down(sm) {
				background-color: initial;
			}
		}
	}
	.column-carousel-wrapper {
		.column-carousel-title {
			visibility: visible;
		}
		
		.column-carousel-name {
			bottom: 50%;
			left: 50%;
			transform: translate(-50%, 50%);
		}
		.column-carousel-link {
			display: block;
		}
	}
	
	.column-carousel-title {
		text-align: center;
		color: $primary;
		visibility: hidden;

		img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
		p {
			margin-bottom: 0;
		}
	}
	.column-carousel-link {
		transition: all 0.5s cubic-bezier(0.68, -0.55, 1, 1.01);
		@include media-breakpoint-up(md) {
			position: absolute;		
			bottom: 5%;
			left: 50%;
			transform: translateX(-50%);
		}
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	.column-carousel-name {
		font-size: 2rem;
		text-align: center;
		position: absolute;
		bottom: 10%;
		margin-bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.5s cubic-bezier(0.68, -0.55, 1, 1.01);
		// transition: all 0.5s cubic-bezier(0.68, -0.55, 0.77, 1.55);
		@include media-breakpoint-up(md) {
			color: $white;
		}
		@include media-breakpoint-down(xl) {
			font-size: 1.5rem;
		}	
		@include media-breakpoint-down(lg) {
			font-size: 1.5rem;
		}	
		@include media-breakpoint-down(md) {
			font-size: 1.25rem;
		}		
	}
	.slide {
		background-color: #ccc;
		padding: 10px;
		display: table-cell !important;
		float: none !important;
	  }
}

#column-carousel-product-gallery {
	.slick-slide {
		height: 163px;
	}
	.slick-next, .slick-prev {		
		top: 40%;
	}
}

.innovation-carousel {
	.slick-slide {
		min-height: 375px;	
	}
}