@mixin make-half-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	// Common properties for all breakpoints
	%grid-column {
		position: relative;
		width: 100%;
		padding-right: $gutter / 2;
		padding-left: $gutter / 2;
	}

	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);
		@for $i from 0 through 11 {
			.col#{$infix}-#{$i}\.5 {
				@extend %grid-column;
			}
		}

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			@for $i from 0 through 11 {
				.col#{$infix}-#{$i}\.5 {
					@include make-col($i + .5, $columns);
				}
			}

			@for $i from 0 through 11 {
				.offset#{$infix}-#{$i}\.5 {
					@include make-col-offset($i + .5, $columns);
				}
			}
		}
	}
}

@if $enable-grid-classes {
	@include make-half-columns();
}
