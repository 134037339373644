@function breakpoint-prev($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n != null and $n > 1, nth($breakpoint-names, $n - 1), null);
}

@mixin partial-fluid-column($side: left) {
	@each $name, $breakpoint in $grid-breakpoints {
		$prev: breakpoint-prev($name);
		@if ($prev) {
			$infix: breakpoint-infix($prev);
			@each $br, $sz in $grid-breakpoints {
				@if ($sz < map-get($grid-breakpoints, $prev)) {
					@include media-breakpoint-up($br) {
						@include media-breakpoint-down(breakpoint-prev($prev)) {
							@include range(1, 11) using($i) {
								$sibling: 12 - $i;
								$width: map-get($container-max-widths, $br);
								@include range(1, $sibling) using ($j) {
									.col#{$infix}-#{$i} ~ .col#{$infix}-#{$j} {
										padding-#{$side}: $grid-gutter-width;
										@if ($sz > 0) {
											max-width: $width;
											margin-#{$side}: calc((100vw - #{$width}) / 2);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

%partial-fluid-container {
	@each $breakpoint, $container-max-width in $container-max-widths {
		@include media-breakpoint-up($breakpoint) {
			$margins: calc(100vw - #{$container-max-width});
			$single-margin: calc(#{$margins} / 2);
			$container-width: calc(100vw - #{$single-margin});
			max-width: $container-width;
		}
	}
}

.left-fluid-container {
	@extend %partial-fluid-container;
	margin-right: auto;
	padding-right: $grid-gutter-width / 2;
	@include partial-fluid-column(left);
}

.right-fluid-container {
	@extend %partial-fluid-container;
	margin-left: auto;
	padding-left: $grid-gutter-width / 2;
	// @include partial-fluid-column(right);
	// Needs :has or JavaScript to implement correctly.
	// Unlike the left-, the right-fluid-container requires the margin be applied to
	// all but the last column.
	// e.g. `.col-md-5:has(+ .col-md-7)`
}

.wide-container {
	@include make-container();
	@include make-container-max-widths();
	@include media-min(1400px) {
		max-width: 1380px;
	}
	@include media-min(1600px) {
		max-width: 1570px;
	}
	@include media-min(1800px) {
		max-width: 1770px;
	}
	@include media-min(2000px) {
		max-width: 1980px;
	}
}
