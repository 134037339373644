$leadings: (
	'none': 1,
	'tight': 1.25,
	'snug': 1.375,
	'normal': 1.5,
	'relaxed': 1.625,
	'loose': 2,
);
@include each-breakpoint using ($prefix) {
	@for $i from 3 through 10 {
		.#{$prefix}leading-#{$i} {
			line-height: ($i / 4) * 1rem;
		}
	}

	@each $leading, $value in $leadings {
		.#{$prefix}leading-#{$leading} {
			line-height: $value;
		}
	}
}
