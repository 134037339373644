.image-mask-transparent-fade > img {
	mask-image: linear-gradient(transparent, black);
}

.bg-image-content {
	.container:first-of-type {
		@include media-breakpoint-only(lg) {
			height: 800px !important;
		}
		@include media-breakpoint-only(md) {
			height: 800px !important;
		}
	}
}

.bg-image-hero-heading {
	.row {
		@include media-breakpoint-down(md) {
			margin-top: 25%;
		}
	}
}