@mixin make-button($colour: $alt-text-grey, $background: #ffffff) {
	display: inline-flex;
	border: 1px solid $primary;
	min-width: 160px;
	min-height: 50px;
	text-align: center;
	justify-content: center;
	align-items: center;
	@include button-effect($colour, $background);
}

@mixin button-effect($effect-color: $primary, $inverted-color: #ffffff) {
	z-index: 2;
	position: relative;
	transition: all 0.3s ease;
	color: $effect-color;
	background-color: $inverted-color;
	&:before {
		transition: all 0.5s ease;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		content: '';
		background-color: #06699d;
		z-index: -1;
	}
	&:hover, &:focus {
		text-decoration: none;
		color: $inverted-color;
		&:before {
			transition: all 0.2s ease;
			opacity: 1;
		}
	}
}