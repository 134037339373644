.btn-light {
    border: 1px solid $primary;
    &:hover:not(:disabled):not(.disabled) {
        background-color: $primary;
        color: $white;
        text-decoration: none;
    }
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: $white;
    background-color: $primary;
    border-color: $primary;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5)
}