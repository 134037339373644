@use 'sass:string';

$widths: (
	'full': 100%,
	'screen': 100vw,
	'min': min-content,
	'max': max-content,
	'fit': fit-content,
);
@include each-breakpoint using ($prefix) {
	@include each-size using ($class, $value) {
		.#{$prefix}w-#{$class} {
			width: $value;
		}
	}

	.#{$prefix}w-auto {
		width: auto;
	}

	$denominator: 1;
	@while $denominator < 13 {
		$numerator: 1;
		@while $numerator < $denominator {
			.#{$prefix}w-#{$numerator}\/#{$denominator} {
				width: ($numerator / $denominator) * 100%;
			}
			$numerator: $numerator + 1;
		}

		$denominator: $denominator + 1;
		@if $denominator == 7 {
			$denominator: 12;
		}
	}

	@each $class, $value in $widths {
		.#{$prefix}w-#{$class} {
			width: $value;
		}
	}
}
