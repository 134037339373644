%list-hover {
	line-height: 1;
	padding: 7px 0;
	font-weight: map-get($font-weights, 'light');
	&:hover {
		background-color: rgba($primary, .25);
	}
}

%placeholder {
	@include this-placeholder() {
		color: $primary;
		@include font-size(20px);
	}
}

%filter {
	position: relative;
	> :is(#category-filter-wrap, .tag-wrap) {
		position: absolute;
		top: 6rem;
		left: 0;
		z-index: 9;
		background-color: #ffffff;
		padding: 1.5rem;
		border-radius: 10px;
		ul {
			list-style-type: none;
			li {
				color: $primary;
				cursor: pointer;
				&.filter-group {
					position: relative;
				}
				span {
					text-transform: uppercase;
					&:after {
						content: '';
						position: absolute;
						right: 1rem;
						top: 25px;
						border: {
							top: 0;
							bottom: var(--arrow-size) solid;
							left: var(--arrow-sides-size) solid transparent;
							right: var(--arrow-sides-size) solid transparent;
						}
					}
				}
				&.collapsed span:after {
					border: {
						top: var(--arrow-size) solid;
						bottom: 0;
						left: var(--arrow-sides-size) solid transparent;
						right: var(--arrow-sides-size) solid transparent;
					}
				}
			}
		}
	}
}

%center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

[data-type="ComponentPostGrid"] {
	.filters {
		flex-direction: column;
		@include media-min(1600px) {
			flex-direction: row;
		}
		#category-filter, #tag-filter {
			@include media-max(1599.98px) {
				margin-bottom: 1.25rem;
			}
		}

		#category-filter {
			width: 326px;
			--arrow-size: 7px;
			--arrow-sides-size: calc(var(--arrow-size) * (5 / 7));
			@extend %filter;
			@include media-breakpoint-down(sm) {
				margin-bottom: 4rem;
				width: 100%;
			}
			> span {
				--arrow-size: 12px;
				--arrow-sides-size: calc(var(--arrow-size) * (5 / 7));
				&:after {
					content: '';
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
					border: {
						top: 0;
						bottom: var(--arrow-size) solid;
						left: var(--arrow-sides-size) solid transparent;
						right: var(--arrow-sides-size) solid transparent;
					}
				}
				&.collapsed:after {
					border: {
						top: var(--arrow-size) solid;
						bottom: 0;
						left: var(--arrow-sides-size) solid transparent;
						right: var(--arrow-sides-size) solid transparent;
					}
				}
			}
			#category-filter-wrap {
				ul li ~ li.filter-group {
					border-top: 1px solid #e3e3e3;
					padding-top: 1rem;
					span:after {
						top: 1.75rem;
					}
				}
			}
			ul li ul {
				transition: height 0.2s ease-in-out;
				margin: 7px 0;
				li {
					@extend %list-hover;
					margin: {
						top: 6px;
						bottom: 6px;
					}
					&[data-active="1"] {
						background-color: rgba($primary, .75);
						color: #ffffff !important;
						&:after {
							content: '\D7';
							margin-top: -2px;
							position: absolute;
							right: 1.1rem;
							font-weight: map-get($font-weights, 'bold');
							display: inline-flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
			&:after {
				content: '&';
				color: $primary;
				left: calc(326px + 1.5rem);
				position: absolute;
				font-size: 1.25rem;

				@include media-breakpoint-down(md) {
					left: 278px;
				}

				@include media-breakpoint-down(sm) {
					left: 50%;
					top: 94px;
				}
			}
		}

		#tag-filter {
			width: 494px;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			input {
				@extend %placeholder;
			}
			div {
				transition: height 0.2s ease-in-out;
			}
			@extend %filter;
			li {
				@extend %list-hover;
			}
		}

		#keyword-search {
			@include media-min(1600px) {
				width: 490px;
			}
			input {
				@extend %placeholder;
			}
		}
	}

	#clear-filters {
		order: 2;
		margin-top: 1.25rem;
		@include media-breakpoint-up(md) {
			margin-top: 0;
			margin-left: 3.5rem;
		}
		+ div {
			order: 1;
		}
		@include media-min(1600px) {
			order: 1;
			margin-left: 0;
			+ div {
				order: 2;
				margin-left: 3.5rem;
			}
		}
	}

	.post-item {
		position: relative;
		overflow-y: hidden;
		height: 280px;
		.content, .description {
			position: absolute;
			bottom: 0;
		}
		.content {
			background-color: rgba(#000000, 0.63);
			width: 100%;
			height: 34px;
			@include media-breakpoint-down(sm) {
				height: 74px;
			}
			transition: height 0.2s ease-in-out;
			@extend %center;
			.title {
				opacity: 1;
				transition: opacity 0.2s ease-in-out;
				color: #ffffff;
				padding: 0.5rem 0;
				font-weight: map-get($font-weights, 'semibold');
			}
		}
		.description {
			width: 100%;
			height: 100%;
			opacity: 0;
			color: #ffffff;
			padding: 1rem;
			transition: opacity 0.2s ease-in-out;
			@extend %center;
		}
		&:hover {
			.content {
				height: 100%;
				.title {
					opacity: 0;
				}
			}
			.description {
				opacity: 1;
			}
		}
	}
}
