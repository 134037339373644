$trackings: (
	'tighter': -0.05em,
	'tight': -0.025em,
	'normal': 0em,
	'wide': 0.025em,
	'wider': 0.05em,
	'widest': 0.1em,
);
@include each-breakpoint using ($prefix) {
	@each $tracking, $value in $trackings {
		.#{$prefix}tracking-#{$tracking} {
			letter-spacing: $value;
		}
	}
}
