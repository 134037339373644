footer {
	.footer__right-social {
		text-align: right;
		svg {
			margin-left: auto;
			width: 50px;
			height: 50px;

			@include media-breakpoint-down(sm) {
				width: 35px;
				height: 35px;
			}
		}

		a:hover svg {
			opacity: 1;
		}
	}
}


#scroll {
	position: fixed;
	right: 35px;
	bottom: 10px;
	cursor: pointer;
	width: 50px;
	height: 50px;
	text-indent: -9999px;
	display: none;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	transition: background-color ease 0.25s;
}

#scroll span {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -8px;
	margin-top: -12px;
	height: 0;
	width: 0;
	border: 8px solid transparent;
}

#scroll:hover {
	opacity: 1;
	filter: "alpha(opacity=100)";
	-ms-filter: "alpha(opacity=100)";
}

footer button {
	&::after {
		position: absolute;
		right: 10%;
		content: "▾";
		font-size: 0.9rem;
	}

	&[aria-expanded="true"]::after {
		content: "▾";
		font-size: 0.9rem;
		-moz-transform: scale(1, -1);
		-webkit-transform: scale(1, -1);
		-o-transform: scale(1, -1);
		-ms-transform: scale(1, -1);
		transform: scale(1, -1);
	}
}
.footer-menu #nav-footer-menu {
	margin-bottom: 0;
}