.article-filter {
	.col-5 {
		@include media-breakpoint-up(sm) {
			margin-left: 1 / 12 * -100%;
		}
	}
	[data-layout=standard] {
		.article-row {
			--gap: 4rem;
			column-gap: var(--gap);
			.article {
				@include media-breakpoint-up(sm) {
					width: calc(50% - var(--gap) / 2);
				}
				div {
					.text-block {
						@include media-breakpoint-up(sm) {
							height: 260px;
						}
						p {
							word-break: break-word;
						}
					}
					@include media-breakpoint-up(lg) {
						height: 253px !important;
					}
				}
			}
		}
		.img-block {
			position: relative;
			&, & :last-child {
				@include media-breakpoint-only(sm) {
					max-height: 238px;
				}
			}
			div:first-child {
				position: absolute;
				--size: 100px;
				width: var(--size);
				height: var(--size);
				outline: 3px solid #d3d3d3;
				transform-origin: center center;
				transform: matrix(0.79, 0.62, -0.62, 0.79, 0, 0);
				transition: transform 0.2s linear;
				z-index: -1;
				@include media-breakpoint-up(sm) {
					--size: 213px;
				}
				@include media-breakpoint-only(md) {
					--size: 290px;
				}
				@include media-breakpoint-up(lg) {
					z-index: 0;
				}
			}
			div:last-child {
				position: relative;
				z-index: 1;
			}
			img {
				max-width: 100%;
				@include media-breakpoint-up(md) {
					max-width: none;
				}
			}
		}
		.article:not(.d-none) {
			&:nth-of-type(4n+2), &:nth-of-type(4n+3) {
				&:hover .img-block div:first-child {
					transform: matrix(0.86, 0.52, -0.52, 0.86, 0, 0);
				}
			}
			&:nth-of-type(4n+1), &:nth-of-type(4n+4) {
				&:hover .img-block div:first-child {
					transform: matrix(0.72, 0.69, -0.69, 0.72, 0, 0);
				}
			}
		}
	}
}