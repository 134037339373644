:root {
	--input-padding-x: 0.75rem;
	--input-padding-y: 0.75rem;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label,
.form-label-group > select,
.form-label-group > textarea {
	padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	/* Override default `<label>` margin */
	line-height: 1.5;
	color: $gray-700;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.1s ease-in-out;
}

.form-row .form-label-group > label {
	//Fix for form row throwing off placeholder elements
	left: 5px;
}
.form-label-group {
	input, select, textarea {
		&::-webkit-input-placeholder,
		&:-ms-input-placeholder,
		&::-ms-input-placeholder,
		&::-moz-placeholder,
		&::placeholder {
			color: transparent;
		}
	}
}

.form-label-group input:not(:placeholder-shown),
.form-label-group select.selected,
.form-label-group textarea:not(:placeholder-shown) {
	padding-top: calc(
		var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
	);
	padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label,
// .form-label-group select:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label {
	padding-top: calc(var(--input-padding-y) / 3);
	padding-bottom: calc(var(--input-padding-y) / 3);
	size: 12px;
	color: $primary;
}

/* Additions */

.form-label-group select ~ label {
	width: 95%;
}

.form-label-group select.selected {
	padding-top: calc(
		var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
	);
	padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group select ~ label {
	padding-top: calc(var(--input-padding-y) / 3);
	padding-bottom: calc(var(--input-padding-y) / 3);
	font-size: 12px;
	color: $primary;
}

.form-label-group select ~ label {
	visibility: hidden;
}
.form-label-group select.selected ~ label {
	visibility: visible;
}

select.form-control:not([size]):not([multiple]) {
	height: auto;
}
