
/**
 ----------------------------------------------------------------------
 * BANNER START
 ----------------------------------------------------------------------
 */

 #banner_wrapper {
	position: relative;
	.banner-gradient {
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.8) 0%,
				transparent 100%
			);
		}
	}
	.banner-caption {
		transform: translateY(-50%);
		top: 50%;
		text-align: left;
		bottom: auto;
		right: auto;
	}
	.slider-caption {
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.4);
	}
	.slider-indicators {
		position: relative;
		bottom: initial;
		align-items: center;
		li {
			display: block;
			border-radius: 50%;
			width: 0.5rem;
			height: 0.5rem;
			margin: 0 0.25rem;
			@include media-breakpoint-up(sm) {
				width: 0.75rem;
				height: 0.75rem;
				margin: 0 0.5rem;
			}
		}
    }
}

div#banner-wrapper.slide {
	.h1, .h2, .h3, .h4, .h5{
		color: $white !important;
	}
}

.banner-image {
    height: 100%;
}