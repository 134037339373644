$static-images-path:'https://res.cloudinary.com/drxlhqaql/image/upload/v1582777280/site_data/sydneykitchenandbathroomrenovations.com.au/static/'; $static-files-path:'https://files.milbel.com.au/file/milbel-site-public/site_data/sydneykitchenandbathroomrenovations.com.au/static/';
// Variables
@import "variables";
@import "functions";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~@accessible360/accessible-slick/slick/slick";
@import "~@accessible360/accessible-slick/slick/slick-theme";
@import "~tiny-slider/dist/tiny-slider.css";
@import "~lightgallery/dist/css/lightgallery.min.css";

//Mixins
@import "mixins";

// Fonts
@import "fonts";

// Utilities
@import "utilities";

@import 'half-columns';
@import 'containers';
@import "general";
@import "announcement";
@import "buttons";
@import "carousel";
@import "carousels/revolving-carousel";
@import "forms";
@import "navigation";
@import "product";
@import "footer";
@import "animations";

@import "article";
@import "archive";
@import "embed";
@import "testimonials";
@import "single-product";
@import "banners";
@import "offcanvas";
@import 'toggle-field';

/* Components */
@import 'components';

@import "cart";
@import "recaptcha";
@import "errors";

/* Plugins */
@import "hero-video";

/* Mobile */
@import "mobile-accordion";

/* IE/Edge */
@import "ms-selectors";

@import "site-specific";