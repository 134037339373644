@use 'sass:string';

@function opposite-side($side) {
	@return if($side == 'left', 'right', 'bottom');
}

$sides: ('x': 'left', 'y': 'top');
@include each-breakpoint using ($prefix) {
	@each $side, $prop in $sides {
		$opposite-side: opposite-side($prop);
		@include each-size using ($class, $value) {
			.#{$prefix}space-#{$side}-#{$class} > :not([hidden]) ~ :not([hidden]) {
				--mb-space-#{$side}-reverse: 0;
				margin-#{$opposite-side}: calc(#{$value} * var(--mb-space-#{$side}-reverse));
				margin-#{$prop}: calc(#{$value} * calc(1 - var(--mb-space-#{$side}-reverse)));
			}
		}

		.#{$prefix}space-#{$side}-px > :not([hidden]) ~ :not([hidden]) {
			--mb-space-#{$side}-reverse: 0;
			margin-#{$opposite-side}: calc(1px * var(--mb-space-#{$side}-reverse));
			margin-#{$prop}: calc(1px * calc(1 - var(--mb-space-#{$side}-reverse)));
		}

		.#{$prefix}space-#{$side}-reverse > :not([hidden]) ~ :not([hidden]) {
			--mb-space-#{$side}-reverse: 1;
		}
	}
}