@import url(https://f002.backblazeb2.com/file/milbel-site-public/fonts/Lato.css);

$heading-font-family: $heading-font-name, Helvetica, Arial, sans-serif;
$paragraph-font-family: $paragraph-font-name, Helvetica, Arial, sans-serif;
$special-font-family: $paragraph-font-family;
@if $special-font-name {
    $special-font-family: $special-font-name, Helvetica, Arial, sans-serif;
}

@if $fetch-heading-font {
    @font-face {
        font-family: $heading-font-name;
        src: url('#{$static-files-path}fonts/#{$heading-font-file}.woff2') format('woff2'),
            url('#{$static-files-path}fonts/#{$heading-font-file}.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
}

@if $fetch-paragraph-font {
    @font-face {
        font-family: $paragraph-font-name;
        src: url('#{$static-files-path}fonts/#{$paragraph-font-file}.woff2') format('woff2'),
            url('#{$static-files-path}fonts/#{$paragraph-font-file}.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
}
@if $fetch-special-font {
    @if $special-font-name {
        @font-face {
            font-family: $special-font-name;
            src: url('#{$static-files-path}fonts/#{$special-font-file}.woff2') format('woff2'),
                url('#{$static-files-path}fonts/#{$special-font-file}.woff') format('woff');
            font-weight: 300;
            font-style: normal;
        }
    }
}

* {
    font-family: $paragraph-font-family;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $heading-font-family;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: 2.8px;
}
